<template>
  <b-card>
    <div slot="header">
      Transacties -
      {{ ((currentPage - 1) * perPage) + 1 }} - {{ ((currentPage) * perPage) }} of {{ totalRows }}

      <b-spinner small v-show="loading" label="Spinning"></b-spinner>
    </div>
    
    <div v-show="totalRows == 0">
      Geen gegevens beschikbaar
    </div>

    <b-container fluid class="p-0" v-show="totalRows > 0">
      <b-row>
        <b-col cols="12" md="12" lg="8" xl="6" class="mb-4">
          <b-button v-b-toggle.collapse-1 variant="primary" class="mr-4">Configureer kolommen</b-button>

          <span class="mr-2">Sorteer op:</span>
          <b-button-group class="mr-4">
            <b-button
                v-on:click="orderBy = 'selected'"
                v-bind:class="{ active: orderBy == 'selected' }">
              Geselecteerde kolommen
            </b-button>
            <b-button
                v-on:click="orderBy = 'quantity'"
                v-bind:class="{ active: orderBy == 'quantity' }">
              Aantal
            </b-button>
            <b-button
                v-on:click="orderBy = 'providerIncome'"
                v-bind:class="{ active: orderBy == 'providerIncome' }">
              Inkomsten
            </b-button>
          </b-button-group>
        </b-col>
        
        <b-col cols="12" md="12" lg="4" xl="4" class="mb-4">
          <span class="mr-2">Export type:</span>
          <b-button-group class="mr-4">
            <b-button
                v-on:click="exportType = 'xlsx'"
                v-bind:class="{ active: exportType == 'xlsx' }">
              Excel
            </b-button>
            <b-button
                v-on:click="exportType = 'csv'"
                v-bind:class="{ active: exportType == 'csv' }">
              CSV
            </b-button>
            <b-button
                v-on:click="exportType = 'tab'"
                v-bind:class="{ active: exportType == 'tab' }">
              TAB
            </b-button>
            <b-button
                v-on:click="exportType = 'html'"
                v-bind:class="{ active: exportType == 'html' }">
              HTML
            </b-button>
          </b-button-group>

          <span id="exportWrapper">
            <b-button variant="primary" class="mr-2" v-on:click="exportTable" :disabled="exportBusy">Export</b-button>
          </span>

          <b-spinner small v-show="exportBusy" label="Spinning"></b-spinner>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-collapse id="collapse-1" class="mt-2" v-model="configureColumnsVisible" >
            <b-card>
              <div class="row">
                <div class="col-3">
                  <h4>Geselecteerd</h4>
                  <draggable class="list-group draggable-group" :list="selectedFields" group="fields" @change="refresh">
                    <div
                      class="list-group-item draggable-item"
                      v-for="(element) in selectedFields"
                      :key="element"
                    >
                      {{ fieldLabels[element] }}
                    </div>
                  </draggable>
                </div>

                <div class="col-3">
                  <h4>Beschikbaar</h4>
                  <draggable class="list-group draggable-group" :list="availableFields" group="fields">
                    <div
                      class="list-group-item draggable-item"
                      v-for="(element) in availableFields"
                      :key="element"
                    >
                      {{ fieldLabels[element] }}
                    </div>
                  </draggable>
                </div>

                <div class="col-4">
                  <h4>Instructies</h4>
                  <p>
                    Sleep kolommen van "beschikbaar" naar "geselecteerd" en andersom om het gewenste overzicht te krijgen.
                    Je kunt middels het slepen ook de volgorde van de kolommen wijzigen, waarmee ook de sorteervolgorde wordt gewijzigd.<br>
                    Klik nogmaals op "Configureer kolommen" om het nieuwe overzicht te bekijken.
                  </p>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>

      <b-row v-show="totalRows > perPage && configureColumnsVisible == false">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"></b-pagination>
        </b-col>
      </b-row>

      <b-row v-if="configureColumnsVisible == false && columnsInitialized == true">
        <b-col>
          <b-table 
              small
              striped
              hover
              id="royalty-transaction-table"
              :items="provider"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage">

            <template v-slot:head()="data">
              {{ fieldLabels[data.column] }}
            </template>

            <template v-slot:head(quantity)="">
              <div class="text-right">Aantal</div>
            </template>

            <template v-slot:head(grossIncome)="">
              <div class="text-right">Inkomsten</div>
            </template>

            <template v-slot:head(providerIncome)="">
              <div class="text-right">Label Share Royalty</div>
            </template>

            <template v-slot:head(distIncome)="">
              <div class="text-right">Boep Share Royalty</div>
            </template>

            <template v-slot:cell(accountingPeriod)="data">
              <div class="text-nowrap">{{ data.item.accountingPeriod | formatAccountingPeriod }}</div>
            </template>

            <template v-slot:cell(track)="data">
              <a href="#" @click.prevent="$emit('item-click', {type: 'track', item: { key: data.item.trackReportingCode, title: data.item.track }})">
                {{ data.item.track }}
              </a>
            </template>
            
            <template v-slot:cell(album)="data">
              <a href="#" @click.prevent="$emit('item-click', {type: 'album', item: { key: data.item.albumReportingCode, title: data.item.album }})">
                {{ data.item.album }}
              </a>
            </template>
            
            <template v-slot:cell(attribution)="data">
              <a href="#" @click.prevent="$emit('item-click', {type: 'attribution', item: { key: data.item.attribution, title: data.item.attribution }})">
                {{ data.item.attribution }}
              </a>
            </template>
            
            <template v-slot:cell(reference)="data">
              <a href="#" @click.prevent="$emit('item-click', {type: 'reference', item: { key: data.item.reference, title: data.item.referenceDescription }})">
                {{ data.item.referenceDescription }}
              </a>
            </template>
            
            <template v-slot:cell(outlet)="data">
              <a href="#" @click.prevent="$emit('item-click', {type: 'outlet', item: { key: data.item.outletId, title: data.item.outlet }})">
                {{ data.item.outlet }}
              </a>
            </template>
            
            <template v-slot:cell(territory)="data">
              <a href="#" @click.prevent="$emit('item-click', {type: 'country', item: { key: data.item.territory, title: data.item.territoryDescription }})">
                {{ data.item.territoryDescription }}
              </a>
            </template>
            
            <template v-slot:cell(quantity)="data">
              <div class="text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.quantity | numberFormat(0, numberLocale) }}</div>
            </template>
            
            <template v-slot:cell(grossIncome)="data">
              <div class="text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.grossIncome | numberFormat(2, numberLocale) }}</div>
            </template>

            <template v-slot:cell(providerIncome)="data">
              <div class="text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.providerIncome | numberFormat(2, numberLocale) }}</div>
            </template>

            <template v-slot:cell(distIncome)="data">
              <div class="text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.distIncome | numberFormat(2, numberLocale) }}</div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
  import moment from 'moment'
  import draggable from 'vuedraggable'
  import axios from 'axios'
    
  export default {
    name: 'RoyaltyTransactionTable',
    components: {
      draggable
    },
    data () {
      return {
        loading: false,
        configureColumnsVisible: false,
        columnsInitialized: false,
        activePerPage: 5,
        cancelToken: null,
        exportType: 'xlsx',
        exportColumns: 'selected',
        exportBusy: false,
        currentPage: 1,
        totalRows: 0,
        orderBy: 'selected',      
        availableFields: [
          'track',
          'album',
          'productIdentifier',
          'albumIdentifier',
          'attribution',
          'territory',
          'outlet',
          'accountingPeriod',
          'activityPeriod',
          'exploitationFormat',
          'transactionType',
          'contract',
        ],
        selectedFields: [
          'reference',
        ],
        fieldLabels: {
          reference: 'Label',
          track: 'Track', 
          album: 'Album',
          attribution: 'Artiest',
          territory: 'Land',
          outlet: 'Portal',
          accountingPeriod: 'Accountingperiode',
          activityPeriod: 'Activiteitperiode',
          exploitationFormat: 'Format',
          transactionType: 'Transactietype',
          contract: 'Contract',
          productIdentifier: 'ISRC',
          albumIdentifier: 'Barcode',
        },
      }
    },
    props: {
      filter: Object,
      sortDirection: String,
      watch: Number,
      perPage: Number,
      numberLocale: String,
    },
    computed: {
      fields () {
        let fields = this.selectedFields.slice()
        fields.push('quantity')
        fields.push('providerIncome')
        if (this.$root.$data.me.Type == 'admin')
          fields.push('distIncome')
        return fields
      },
    },
    watch: {
      watch: {
        handler: 'refresh', 
      },
      
      orderBy: {
        handler: 'refresh', 
        deep: true
      },
    },
    methods: {
      moment: moment,
      refresh () {
        if (this.cancelToken) {
          this.cancelToken.cancel()
          this.cancelToken = null
        }
        this.$root.$emit('bv::refresh::table', 'royalty-transaction-table')
      },
      provider (ctx) {
        if ((this.filter.apFrom == null) || (this.filter.apTo == null)) return []

        return new Promise((resolve) => {
          if (this.cancelToken) {
            this.cancelToken.cancel()
          }
          this.cancelToken = axios.CancelToken.source()

          let fields = this.fields.slice()

          ctx['fields'] = fields
          ctx['filter'] = this.filter

          if (this.orderBy != 'selected')
            ctx['orderBy'] = [this.orderBy + ' ' + this.sortDirection.toUpperCase()];
          else
            ctx['orderBy'] = []

          this.loading = true
          this.$http
              .post('analytics/royalty-transactions', ctx, { cancelToken: this.cancelToken.token })
              .then(response => {
                  let data = response.data
                  this.totalRows = Number(data.count)
                  this.loading = false
                  
                  resolve(data.rows)
              }).catch(function () {
                  resolve([])
              })
        })
      },
      exportTable () {
        let params = {
          exportFileType: this.exportType,
          exportColumns: 'selected',
          fields: this.fields,
          filter: this.filter,
          numberLocale: this.numberLocale,
          filename: 'RoyaltyAnalyticsExport_'+ moment().format('YYYYMMDD_HHmm'),
        }

        // Start export
        this.exportBusy = true
        this.$http
          .post('analytics/royalty-transactions', params)
          .then((result) => {
            let jobId = result.data.jobId
            setTimeout(() => {
              this.$http.get('users/me/jobs/' + jobId)
                .then(response => {
                  if (response.data.status == 'done') {
                    let link = document.createElement('a')
                    link.href = '/api/users/me/export-files/' + jobId
                    document.body.appendChild(link)
                    link.click()
                  } else {
                    this.$bvModal.msgBoxOk('Export gestart, bekijk het menu-item "Exports" om te downloaden.')
                  }
                  this.exportBusy = false
                }).catch(() => {
                  this.$bvModal.msgBoxOk('Er ging helaas iets mis tijdens de export. Probeer het nogmaals.')
                  this.exportBusy = false
                });
            }, 2000)
          }).catch(() => {
            this.$bvModal.msgBoxOk('Er ging helaas iets mis tijdens de export. Probeer het nogmaals.')
            this.exportBusy = false
          })
      }
    },
    mounted () {
      if (this.$root.$data.me.Username == 'adrie') {
        this.availableFields = [
          'accountingPeriod',
          'activityPeriod',
          'exploitationFormat',
          'transactionType',
          'contract'
        ]
        
        this.selectedFields = [
          'transactionType',
          'reference',
          'outlet',
          'attribution',
          'track',
          'album',
          'territory',
        ]
      }
      this.columnsInitialized = true
    }
  }
</script>

<style scoped>
  .list-group-item {
    padding: 2px 5px 2px 5px;
  }

  .draggable-item {
    cursor: move; 
    cursor: grab;
  }

  .draggable-item:active {
    cursor: grabbing;
  }

  .draggable-item:hover {
    background-color: lightblue;
  }

  .draggable-group {
    padding-bottom: 25px;
    min-height: 100px;
  }
</style>
